<template>
  <div class="my-second juan bgcolor">
      <Head :title="$t('mine.mine')+$t('mine.my_choucode')" :show="true" />
      <div class="common-box juan-box" v-if="list.length>0">
          <div class="mycode-main">
              <MyList ref="mylist" :listadd="listadd">
                <div class="code-item" v-for="(l,i) in list" :key="i">
                    <p class="top">
                        <span>
                            {{$t('mine.get')+$t('mine.my_choucode')}}
                        </span>
                        <span>
                            {{l.buy_micro_formate}}
                        </span>
                    </p>
                    <p class="two">
                        <span>
                            {{l.lucky_num}}
                        </span>
                    </p>
                </div>
              </MyList> 
          </div>
      </div>
      <div class="common-box juan-box" v-else>
        <div class="no-box">
            <img class="juan-no" src="../../assets/images/juan-no.png" alt="">
            <p class="no-text">{{$t('mine.zhan_no')+$t('mine.my_choucode')}}</p>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name:"codelist",
  data(){
    return {
        list:[],
        page:1,
        pageSize:50,
        total:0
    }
  },
  methods:{
      listadd(callback){
          if(this.page<Math.ceil(this.total/this.pageSize)){
            this.page++;
            this.$ajax.luckynumlist({
                    good_id:this.$route.query.good_id,
                    qishu:this.$route.query.qishu,
                    page:this.page,
                    pageSize:this.pageSize
                })
                .then(res=>{
                    if(res.code==200){
                        this.list = this.list.concat(res.data.codelist.data)
                        callback()
                    }
                })
          }else{
               this.$refs.mylist.finished = true;  // 加载结束 
          }
      }
  },
  mounted(){
    this.$ajax.luckynumlist({
        good_id:this.$route.query.good_id,
        qishu:this.$route.query.qishu,
        page:this.page,
        pageSize:this.pageSize
    })
    .then(res=>{
        if(res.code==200){
            this.list = res.data.codelist.data;
            this.total = res.data.codelist.total
        }
    })
    // this.list = JSON.parse(localStorage.getItem("myCodeList"))
  }
}
</script>